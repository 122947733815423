define("admin/components/pilot-license-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotLicenseViewComponent;
  PilotLicenseViewComponent = Ember.Component.extend({
    classNames: 'row pilot-section',
    displayedIndex: Ember.computed('index', function () {
      return this.get('index') + 1;
    }),
    dateLabel: Ember.computed('license.reference_date_mode', function () {
      var mode;
      mode = this.get('license.reference_date_mode');
      switch (mode) {
        case 'expiration_date':
          return 'Expires';
        case 'issue_date':
          return 'Issued';
        default:
          return 'Date';
      }
    })
  });
  var _default = _exports.default = PilotLicenseViewComponent;
});