define("admin/components/clients/client-form", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ClientFormComponent = Ember.Component.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    isDownloadingBillingReport: false,
    clientLoginParams: Ember.computed('model', function () {
      return {
        client_number: this.get('client.id'),
        client_first_name: this.get('client.first_name'),
        client_last_name: this.get('client.last_name')
      };
    }),
    clientVerticalsString: Ember.computed('client.verticals.[]', function () {
      return this.get('client.verticals').getEach('name').join(', ');
    }),
    canLoginAsClient: Ember.computed('model.admin', function () {
      const currentAdmin = this.get('currentAdmin');
      return currentAdmin.roles.includes('auth0_access');
    }),
    actions: {
      editProfile: function () {
        this.get('editClientModal')();
      },
      downloadBilling: function () {
        if (this.get('isDownloadingBillingReport')) {
          return false;
        }
        this.set('isDownloadingBillingReport', true);
        Ember.$.ajax({
          url: `${_environment.default.api.host}/v1/admin/clients/${this.get('client.id')}/invoice_line_items.csv`,
          headers: this.get('sessionAccount.headers'),
          type: 'GET'
        }).then((response, status, xhr) => {
          let a = document.createElement('a');
          a.style = "display: none";
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(new Blob([response], {
            type: 'text/csv'
          }));
          a.href = url;
          const filename = xhr.getResponseHeader('Content-Disposition').match(/attachment; filename="([^"]+)"/)[1];
          a.download = filename ? filename : 'billing-report.csv';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          this.set('isDownloadingBillingReport', false);
        }).catch(() => {
          alert('There was an issue generating billing report');
          this.set('isDownloadingBillingReport', false);
        });
      },
      update: function (model) {
        model.save();
      },
      preSendWelcomeEmail: function (type) {
        if (this.get('client.welcome_email_sent')) {
          const send = confirm("You're going to resend welcome email to this user. Are you sure?");
          if (send) {
            this.send('sendWelcomeEmail', type);
          }
        } else {
          this.send('sendWelcomeEmail', type);
        }
      },
      sendWelcomeEmail: function (type) {
        Ember.$.ajax({
          url: `${_environment.default.api.host}/v1/admin/clients/${this.get('client.id')}/send_email`,
          headers: this.get('sessionAccount.headers'),
          type: 'POST',
          data: {
            type: type
          },
          dataType: 'json'
        }).then(() => {
          this.set('client.welcome_email_sent', moment());
          this.set('client.welcome_email_type', type);
          this.set('emailSent', true);
          Em.run.later(() => {
            this.set('emailSent', false);
          }, 1500);
        });
      },
      toggleOpenImportProdDataModal: function () {
        this.set('showImportMissionModal', true);
      }
    }
  });
  var _default = _exports.default = ClientFormComponent;
});