define("admin/routes/pilots/pilot", ["exports", "ember-infinity/mixins/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotsEditRoute;
  PilotsEditRoute = Ember.Route.extend(_route.default, {
    session: Ember.inject.service(),
    model: function (params) {
      params['perPage'] = 100;
      params['startingPage'] = 1;
      params['modelPath'] = 'controller.missions';
      return Ember.RSVP.hash({
        pilot: this.store.findRecord('pilot', params.pilot_id),
        missions: this.infinityModel('mission', params),
        workOrders: this.infinityModel('site-inspection', params),
        badges: this.store.query('badge', {}),
        currencies: this.store.query('currency', {}),
        countries: this.store.query('country', {}),
        pilotRateTypes: this.store.query('pilot-rate-type', {}),
        currentAdmin: this.store.findRecord('admin', this.get('session.data.authenticated.id'))
      });
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      model.pilot.set('approve', model.pilot.get('status') === 'approved');
      return controller.set('missions', model.missions);
    },
    actions: {
      savePilot: function (pilot) {
        var _this;
        _this = this;
        return pilot.save().then(function (response) {
          return _this.transitionTo('pilots.index');
        }, function (response) {
          return alert(response.data);
        });
      }
    },
    queryParams: {
      sort_attribute: {
        refreshModel: true
      },
      sort_order: {
        refreshModel: true
      }
    }
  });
  var _default = _exports.default = PilotsEditRoute;
});