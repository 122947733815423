define("admin/helpers/file-extension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let fileExtensionHelper = Ember.Helper.helper(function ([url]) {
    if (typeof url === 'string') {
      const match = url.match(/\.([0-9a-z]+)([?#]|$)/i);
      if (match && match.length > 1) {
        return match[1].toLowerCase();
      }
    }
    return null;
  });
  var _default = _exports.default = fileExtensionHelper;
});