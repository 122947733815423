define("admin/components/mission-weather", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionWeatherComponent;
  MissionWeatherComponent = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    classNames: '',
    startIndex: Ember.computed('forecasts', 'forecasts.[]', function () {
      var missionStartDate;
      missionStartDate = new Date(new Date(this.get('mission.scheduled_at_start')).toLocaleString('en', {
        timeZone: this.get('mission.location.timezone_id')
      }));
      return this.get('forecasts').findIndex(function (_this) {
        return function (el) {
          var forecastDate;
          forecastDate = el.date;
          if (!forecastDate) {
            return;
          }
          forecastDate = new Date(new Date(forecastDate).toLocaleString('en', {
            timeZone: 'Etc/UTC'
          }));
          return forecastDate.getDate() === missionStartDate.getDate() && forecastDate.getMonth() === missionStartDate.getMonth() && forecastDate.getFullYear() === missionStartDate.getFullYear();
        };
      }(this));
    }),
    forecastsAreEmpty: Ember.computed('startIndex', function () {
      return this.get('startIndex') === -1;
    }),
    showArrows: Ember.computed('forecasts', 'forecasts.[]', function () {
      var length;
      length = this.get('forecasts.length');
      return length > 1;
    }),
    endIndex: Ember.computed('forecasts', 'forecasts.[]', function () {
      return this.get('forecasts.length') - 1;
    }),
    didInsertElement: function () {
      return Ember.run.scheduleOnce('afterRender', this, function () {
        return this.missionForecast();
      });
    },
    missionForecast: function () {
      return Ember.$.ajax({
        url: _environment.default.api.host + "/v1/admin/missions/" + this.get('mission.id') + "/weather",
        headers: this.get('sessionAccount.headers'),
        type: 'GET',
        dataType: 'json'
      }).then(function (_this) {
        return function (response) {
          return _this.set('forecasts', response.data);
        };
      }(this), function (_this) {
        return function (response) {
          console.log('error getting weather data');
          return console.log(response);
        };
      }(this));
    }
  });
  var _default = _exports.default = MissionWeatherComponent;
});