define("admin/controllers/pilots/pilot", ["exports", "admin/utils/send-error-to-sentry", "admin/utils/get-error-message-from-response"], function (_exports, _sendErrorToSentry, _getErrorMessageFromResponse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_CURRENCY_CODE = 'USD';
  var _default = _exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    showCountryDropdown: false,
    searchCountry: null,
    badgeSortProps: ['badge.internal_name:asc'],
    queryParams: ['activeTab', 'sort_attribute', 'sort_order'],
    activeTab: 'profile',
    sortProperties: ['scheduled:desc'],
    sort_attribute: 'scheduled',
    // available options: created, scheduled
    sort_order: 'desc',
    // desc or asc
    pilotLoginParams: Ember.computed('model', function () {
      return {
        pilot_number: this.get('model.pilot.id'),
        pilot_first_name: this.get('model.pilot.first_name'),
        pilot_last_name: this.get('model.pilot.last_name')
      };
    }),
    pilotBadgeList: Ember.computed.sort('model.pilot.pilot_badges', 'badgeSortProps'),
    canLoginAsClient: Ember.computed('model.admin', function () {
      const currentAdmin = this.get('model.currentAdmin');
      return currentAdmin.roles.includes('auth0_access');
    }),
    countryOptions: Ember.computed('model.countries', function () {
      return this.get('model.countries').map(country => {
        return Ember.Object.create({
          label: country.name,
          value: country.name
        });
      });
    }),
    badgeSelectList: Ember.computed('model.pilot.pilot_badges', function () {
      const allBadges = this.get('model.badges').toArray();
      const pilotBadges = this.get('model.pilot.pilot_badges');
      const badges = [];
      pilotBadges.forEach(pilotBadge => badges.push(pilotBadge.get('badge')));
      return $(allBadges).not(badges).sort(function (a, b) {
        const aName = a.get('name');
        const bName = b.get('name');
        return aName.toLowerCase().localeCompare(bName.toLowerCase());
      });
    }),
    pilotRateTypes: Ember.computed('model.pilotRateTypes', function () {
      return this.get('model.pilotRateTypes')?.map(pilotRateType => pilotRateType.name) || [];
    }),
    payoutRates: Ember.computed("model.pilot.pilot_rates", function () {
      const rates = {};
      this.pilotRateTypes.forEach(key => {
        const rate = this.get("model.pilot.pilot_rates").findBy("rate_type", key);
        rates[key] = rate?.get("payout_amount") || 0;
      });
      return rates;
    }),
    currencyUpdated: false,
    currencySelected: Ember.computed('model.pilot.currency', function () {
      return this.model.pilot.get('currency') || this.model.currencies.findBy('code', DEFAULT_CURRENCY_CODE);
    }),
    actions: {
      addBadge(badge) {
        const pb = this.store.createRecord('pilot_badge', {
          pilot: this.get('model.pilot'),
          badge
        });
        return pb.save();
      },
      changeCountry(country) {
        this.get('model.pilot').set('country', country);
      },
      removeBadge(pilotBadge) {
        return pilotBadge.destroyRecord();
      },
      setTab(tab) {
        return this.set('activeTab', tab);
      },
      submit() {
        return this.get('model.pilot').save().then(() => {
          this.flashMessages.success('Pilot was saved');
        }).catch(response => {
          (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
          this.flashMessages.error((0, _getErrorMessageFromResponse.default)(response));
        });
      },
      setSort(sort) {
        // needed for sortable-column to know what column is selected/active
        this.set('sortProperties', [sort]);

        // these values will be passed to BE for sorting options
        const arrSort = sort.split(':');
        this.set('sort_attribute', arrSort[0]);
        return this.set('sort_order', arrSort[1]);
      },
      setPilotCurrency(currencyCode) {
        this.model.pilot.set('currency', this.model.currencies.find(currency => currency.code === currencyCode));
        this.currencyUpdated = true;
      },
      savePilotCurrencyAndPayoutRates() {
        // need to update everything if currency is changed
        if (this.currencyUpdated) {
          this.model.pilot.save();
          const newCurrency = this.model.pilot.get('currency');
          this.pilotRateTypes.forEach(payoutRateType => {
            const rate = this.get("model.pilot.pilot_rates").findBy("rate_type", payoutRateType);
            if (rate) {
              rate.set("currency", newCurrency);
              rate.save();
            }
          });
        }
        // otherwise just update the rates created or updated
        const newRateAmounts = this.get('payoutRates');
        const pilotRates = this.get('model.pilot.pilot_rates');
        const currency = this.model.pilot.get('currency') || this.model.currencies.findBy('code', DEFAULT_CURRENCY_CODE);
        this.pilotRateTypes.forEach(rateType => {
          const pilotRate = pilotRates.findBy("rate_type", rateType);
          const newAmount = newRateAmounts[rateType];
          if (pilotRate) {
            const prevAmount = pilotRate.get("payout_amount");
            if (prevAmount !== newAmount) {
              pilotRate.set("payout_amount", newAmount);
              pilotRate.save();
            }
          } else if (newAmount) {
            // rate is being set for the first time
            this.store.createRecord('pilot_rate', {
              rate_type: rateType,
              payout_amount: newRateAmounts[rateType],
              currency,
              pilot: this.model.pilot
            }).save();
          }
        });
      }
    }
  });
});