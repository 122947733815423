define("admin/routes/clients/client", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    model: function (params) {
      return Ember.RSVP.hash({
        client: this.store.findRecord('client', params.client_id),
        verticalsToSelect: this.store.findAll('vertical'),
        currencies: this.store.query('currency', {
          all: true
        }),
        currentAdmin: this.store.findRecord('admin', this.get('session.data.authenticated.id'))
      });
    },
    setupController: function (controller, model) {
      const verticals = model.verticalsToSelect;
      const routeActions = this.actions;
      let newModel = model.client;
      this._super(controller, newModel);
      controller.set('verticalsToSelect', verticals);
      controller.set('routeActions', routeActions);
      controller.set('currentAdmin', model.currentAdmin);
    },
    actions: {
      createClient: function (client, organization) {
        if (!client.validate()) {
          return false;
        }
        client.set('organization', organization);
        client.save().then(() => {
          this.send('closeModal');
          this.transitionToRoute('clients.client', client.id);
        }, () => {
          // Noop, Needed so the save doesn't blow up
        });
      },
      closeModalNewClient: function () {
        this.send('closeModal');
      }
    }
  });
});