define("admin/controllers/pilots/pilot/missions/index", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    badgeSortProps: ['badge.internal_name:asc'],
    queryParams: ['activeTab', 'sort_attribute', 'sort_order'],
    activeTab: 'profile',
    sortProperties: ['scheduled:desc'],
    sort_attribute: 'scheduled',
    // available options: created, scheduled
    sort_order: 'desc',
    // desc or asc
    pilotLoginParams: Ember.computed('model', function () {
      return {
        pilot_number: this.get('model.pilot.id'),
        pilot_first_name: this.get('model.pilot.first_name'),
        pilot_last_name: this.get('model.pilot.last_name')
      };
    }),
    pilotBadgeList: Ember.computed.sort('model.pilot.pilot_badges', 'badgeSortProps'),
    badgeSelectList: Ember.computed('model.pilot.pilot_badges', function () {
      const allBadges = this.get('model.badges').toArray();
      const pilotBadges = this.get('model.pilot.pilot_badges');
      const badges = [];
      pilotBadges.forEach(pilotBadge => badges.push(pilotBadge.get('badge')));
      return $(allBadges).not(badges).sort(function (a, b) {
        const aName = a.get('name');
        const bName = b.get('name');
        return aName.toLowerCase().localeCompare(bName.toLowerCase());
      });
    }),
    // TODO: rate types are still hard coded in HBS due to requirement for object dot notation
    pilotRateTypes: Ember.computed("model.pilotRateTypes", function () {
      return this.get("model.pilotRateTypes").map(pilotRateType => pilotRateType.name);
    }),
    payoutRates: Ember.computed("model.pilot.pilot_rates", function () {
      const rates = {};
      this.pilotRateTypes.forEach(key => {
        const rate = this.get("model.pilot.pilot_rates").findBy("name", key);
        rates[key] = rate?.get("amount") || 0;
      });
      return rates;
    }),
    currencyUpdated: false,
    actions: {
      deletePayoutAccount() {
        if (window.confirm('Are you sure? This will permanently remove the payout account details the ability to cross check payout history.')) {
          const headers = this.get('sessionAccount.headers');
          return Ember.$.ajax({
            url: `${_environment.default.api.host}/v1/admin/pilots/${this.model.pilot.id}/payout_accounts`,
            type: 'DELETE',
            dataType: 'json',
            headers
          });
        }
      },
      addBadge(badge) {
        const pb = this.store.createRecord('pilot_badge', {
          pilot: this.get('model.pilot'),
          badge
        });
        return pb.save();
      },
      removeBadge(pilotBadge) {
        return pilotBadge.destroyRecord();
      },
      setTab(tab) {
        return this.set('activeTab', tab);
      },
      setSort(sort) {
        // needed for sortable-column to know what column is selected/active
        this.set('sortProperties', [sort]);

        // these values will be passed to BE for sorting options
        const arrSort = sort.split(':');
        this.set('sort_attribute', arrSort[0]);
        return this.set('sort_order', arrSort[1]);
      },
      setPilotCurrency(currencyCode) {
        this.model.pilot.set('currency', this.model.currencies.find(currency => currency.code === currencyCode));
        this.currencyUpdated = true;
      },
      savePilotCurrencyAndPayoutRates() {
        // need to update everything if currency is changed
        if (this.currencyUpdated) {
          this.model.pilot.save();
          const newCurrency = this.model.pilot.get('currency');
          this.pilotRateTypes.forEach(payoutRateType => {
            const rate = this.get("model.pilot.pilot_rates").findBy("name", payoutRateType);
            if (rate) {
              rate.set("currency", newCurrency);
              rate.save();
            }
          });
        }
        // otherwise just update the rates created or updated
        const newRateAmounts = this.get('payoutRates');
        const pilotRates = this.get('model.pilot.pilot_rates');
        this.pilotRateTypes.forEach(rateType => {
          const pilotRate = pilotRates.findBy("name", rateType);
          const newAmount = newRateAmounts[rateType];
          if (pilotRate) {
            const prevAmount = pilotRate.get("amount");
            if (prevAmount !== newAmount) {
              pilotRate.set("amount", newAmount);
              pilotRate.save();
            }
          } else if (newAmount) {
            // rate is being set for the first time
            this.store.createRecord('pilot_rate', {
              name: rateType,
              amount: newRateAmounts[rateType],
              currency: this.model.pilot.get('currency'),
              pilot: this.model.pilot
            }).save();
          }
        });
      }
    }
  });
});